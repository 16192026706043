import * as React from "react"
import {graphql } from "gatsby"

import Layout from "../../components/layout"
import Sidebar from "../../components/sidebar"
import Seo from "../../components/seo"
import Navbar from "../../components/navbar"
import Internalbanner from "../../components/internalbanner"
import Imagetrio from "../../components/imagetrio"
import Findus from "../../homepage/findus"
import Homeswiper from "../../homepage/homeswiper"
import Reformfooter from "../../components/footer"
import Yogaback from "../../images/yoga3.jpg"
import YogaPic1 from "../../images/studio/improvers-1.jpg"
import YogaPic2 from "../../images/studio/improvers-2.jpg"
import YogaPic3 from "../../images/studio/improvers-3.jpg"
import YogaPic4 from "../../images/studio/improvers-4.jpg"


const Improvers_reformer = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Improvers Reformer`


  return (
    <Layout location={location} title={siteTitle}>
    <Seo title="Improvers Reformer Pilates" description="Improvers Pilates classes at Reform Pilates Cork"/>
    <Navbar />
    <Internalbanner title="Improvers Reformer" subtitle="A total body workout in a low-risk intensity session" bannerpic={YogaPic2}/>
    
          <section className="internal-page-main gencont">
          <div className="container">
          <div className="columns is-marginless">

          <div className="column is-8">
                  <h3>Improvers Reformer</h3><hr/>
    <p class="toningtag">
    Perfect for: <span className="tag">toning arms</span><span className="tag">legs</span><span className="tag">defining waist</span> <span className="tag">posture</span> <span className="tag">core strength</span>
    </p>
     <p class="blacksignature">The best way to advance your Reformer progress</p>
          <p>Once you’ve completed our Beginners course (or if you’ve a good foundation of Reformer Pilates already), our Improvers Reformer class is a challenging, full-body workout that tones your core and improves fitness levels further.</p>
          <div className="columns is-marginless is-centered">
          <div className="column is-8 is-paddingless">
          <img src={YogaPic1} className="midyogapic" alt="Improvers Reformer" />
          </div>

          </div>

          <p>Clients see improvements attending even once a week, while those that can attend twice a week on a regular basis benefit significantly from the very effective additional 1-hour workout. </p>
          <p>Buy a <strong>5-class pack</strong> and attend when you can by booking online ahead of time. This class is popular with regular walkers, runners and golfers; in fact, Reformer Pilates can help with many areas of your active life!</p> 
          </div>
          <div className="column is-3 is-offset-1">
          <Sidebar />
          </div>
          </div>
          </div>
          </section>
    <Imagetrio imagea={YogaPic2} imageb={YogaPic3} imagec={YogaPic4} />
    <Findus />
    <Homeswiper />
    <Reformfooter />
    </Layout>
    )
}

export default Improvers_reformer

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    nodes {
      excerpt
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
}
`
